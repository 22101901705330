import React, { Component } from 'react';
import './App.css';
import ModelList from "./components/ModelList";

class App extends Component {



    render() {
        return (
        <div className="App">
            <ModelList/>
        </div>
    );
    }
}

export default App;
