//@flow
import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import {registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import {withRouter} from 'react-router-dom'

registerPlugin(FilePondPluginImagePreview);

class Frontpage extends Component {
    constructor(props) {
        super(props);

        this.postForside = this.postForside.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            ID: this.props.match.params.id,
            modellNavn: "",
            publikasjon: "",
            fotograf: "",
            type: "",
            modellID: "",
            bildenavn: "",
            modeller: [],

        }
    }


    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;
        if (images != null) {
            // console.log(images)
            return images.map(img => {
                photoIndex++;
                return (
                    <div key={photoIndex} className="testimage1">
                        <img
                            src={"https://www.trendmodels.no/" + img.filnavn}
                            alt="Modellimg"
                            id={img.filnavn}
                            className="modelimg"
                            onClick={() =>
                                this.selectImage(img.filnavn)
                            }
                        />

                    </div>
                );
            })
        }
    }


    selectImage(img) {
        const {images} = this.state;
        images.map(imageSrc => {
            document.getElementById(imageSrc.filnavn).classList.remove('testimage2');
        })

        let img2 = img.substring(img.lastIndexOf('/') + 1);
        console.log("img2; " + img2)
        document.getElementById("filnavn").value = img2;
        document.getElementById(img).classList.toggle('testimage2');
        this.setState((state, props) => ({
            filnavn: img2
        }));
    }

    updateModelList = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;


        console.log("Getting models with type: ", value)
        console.log(this.state.modeller)
        const API = 'https://admin.trendmodels.no/backend/all.php?filter=';
        const DEFAULT_QUERY = value;

        console.log("Fetsher ", API + DEFAULT_QUERY)
        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({modeller: data.records}));
        console.log(this.state.modeller)
    }
    updateModelList2() {
        let API = 'https://admin.trendmodels.no/backend/all.php?filter=';
        let DEFAULT_QUERY = this.state.type;

        console.log("updateModelList2 Fetsher ", API + DEFAULT_QUERY)
        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({modeller: data.records}));
        console.log(this.state.modeller)

        API = 'https://admin.trendmodels.no/backend/bilder.php?ID=';
        DEFAULT_QUERY = this.state.modellID;

        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({images: data}))
    }

    updateImageList = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        document.getElementById("modellID").value = value;
        let API = 'https://admin.trendmodels.no/backend/bilder.php?ID=';
        let DEFAULT_QUERY = value;

        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({images: data}))
    }


    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        console.log("Adding: ", name, " : ", value)
        this.setState((state, props) => ({
            [name]: value
        }));

        console.log("STATE: ", this.state)
    };

    componentDidMount() {
        this.loadData();

    }

    loadData() {

        let API = 'https://admin.trendmodels.no/backend/forside.php?ID=';
        let DEFAULT_QUERY = this.props.match.params.id;

        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({
                    hits: data.records,
                    ID: data.records[0].ID,
                    filnavn: data.records[0].filnavn,
                    type: data.records[0].type,
                    modellID: data.records[0].modellID,
                    modellNavn: data.records[0].modellNavn,
                    publikasjon: data.records[0].publikasjon,
                    fotograf: data.records[0].fotograf,

                }, () => this.updateModelList2())
            )

    }


    postForside() {

        let url = 'https://admin.trendmodels.no/backend/forsideedit.php';
        let data = {
            ID: this.props.match.params.id,
            type: this.state.type,
            modellID: this.state.modellID,
            filnavn: this.state.filnavn,
            modellNavn: this.state.modellNavn,
            publikasjon: this.state.publikasjon,
            fotograf: this.state.fotograf,
        };
        console.log("Sending update\n", JSON.stringify(data))
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Success: ", response.hurra);
                this.setState({
                    formresponse: response.hurra
                })
            })
            .catch(error => console.error('Error:', error));
    }

    render() {

        return (
            <div>
                <h1>Rediger data!</h1>


                <Container>
                    <Row>
                        <Col className="md-6"><Form>
                            <Row className="justify-content-md-center">
                                <Col sm="8">

                                    <Form.Group as={Row} controlId="type" onChange={this.updateModelList}>
                                        <Form.Label column sm="4">Type</Form.Label>
                                        <Col sm="8">
                                            <Form.Control
                                                as="select"
                                                value={this.state.type}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">Velg modelltype</option>
                                                <option value="newfaces">Women - New faces</option>
                                                <option value="w_fashion">Women - Fashion</option>
                                                <option value="w_model">Women - Classic</option>
                                                <option value="c_girl">Women - Teen / kids</option>
                                                <option value="m_newfaces">Men - New faces</option>
                                                <option value="m_fashion">Men - Fashion</option>
                                                <option value="m_classic">Men - Classic</option>
                                                <option value="c_boy">Men - Teen / kids</option>
                                                <option value="limbo">Limbo</option>
                                                <option value="slett">Slett</option>
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="justify-content-md-center">
                                <Col sm="8">

                                    <Form.Group as={Row} controlId="modellID" onChange={this.updateImageList}>
                                        <Form.Label column sm="4">Modell:</Form.Label>
                                        <Col sm="8">
                                            <Form.Control as="select" value={this.state.modellID}
                                                          onChange={this.handleChange}>
                                                <option value="">Velg modell</option>

                                                {this.state.modeller.map(casen => (
                                                    <option key={casen.ID} value={casen.ID}>{casen.fornavn} {casen.etternavn}</option>
                                                ))}

                                            </Form.Control>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="justify-content-md-center">
                                <Col sm="8">

                                    <Form.Group as={Row} controlId="modellNavn" onChange={this.handleChange}>
                                        <Form.Label column sm="4">
                                            Modellnavn
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control defaultValue={this.state.modellNavn}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="publikasjon" onChange={this.handleChange}>
                                        <Form.Label column sm="4">
                                            Publikasjon
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control defaultValue={this.state.publikasjon}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="fotograf" onChange={this.handleChange}>
                                        <Form.Label column sm="4">
                                            Fotograf
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control defaultValue={this.state.fotograf}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="modellID" onChange={this.handleChange}>
                                        <Form.Label column sm="4">
                                            ID
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control defaultValue={this.state.modellID}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="filnavn" onChange={this.handleChange}>
                                        <Form.Label column sm="4">
                                            Filnavn
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control defaultValue={this.state.filnavn}/>
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group as={Row}>
                                <Col sm="12">
                                    <button type="button" onClick={this.postForside}
                                            className="btn btn-primary">Lagre endringer
                                    </button>
                                    <p>{this.state.formresponse}</p>
                                </Col>
                            </Form.Group>
                        </Form></Col>
                        <Col className="md-6">
                            <Row>
                                <div className="previewImage_container">
                                    <img
                                        src={"http://www.trendmodels.no/modeller/" + this.state.modellID + "/" + this.state.filnavn}
                                        className="previewImage"/></div>
                            </Row>
                            <Row>
                                <div className="previewImage_container">
                                    <div className="title-preview">
                                        <span className="fp-modellnavn">{this.state.modellNavn}</span>{" "}
                                        <span className="fp-publikasjon">for</span>{" "}
                                        <span className="fp-publikasjon">{this.state.publikasjon}</span>{" "}
                                        <span className="fp-fotograf">shot</span>{" "}
                                        <span className="fp-fotograf">by</span>{" "}
                                        <span className="fp-fotograf">{this.state.fotograf}</span>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <div className="mt-5">
                    <StackGrid
                        gridRef={grid => this.grid = grid}
                        itemComponent="div"
                        monitorImagesLoaded={true}
                        columnWidth={130}
                        gutterWidth={20}
                    >
                        {this.renderImages()}
                    </StackGrid>
                </div>

            </div>
        )
    }
}


export default Frontpage;
