//@flow
import React, {Component} from "react";
import {
    withRouter
} from 'react-router-dom'

class Newmodels extends Component<{
    match: { params: { id: number } }
}> {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            checked: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
    }

    state = {}

    handleChange(e) {
        this.props.handleCheckbox(e.target.value);
    }

    deleteModel(id) {
        console.log("Sletter modell med id " + id)
        console.log(this.state.hits)

        const removeFav = this.state.hits.filter(item => item.ID !== id)
        this.setState({
            hits: removeFav
        })
        let API = 'https://admin.trendmodels.no/backend/deletenewmodel.php?ID=';
        let DEFAULT_QUERY = id;
        return fetch(API + DEFAULT_QUERY, {
            credentials: 'include',
            method: 'delete'
        })
            .then(response => response.json());
    }

    handleEdit(e) {
        this.props.handleEditButton(e);
    }

    componentDidMount() {
        const API = 'https://admin.trendmodels.no/backend/newmodels.php';

        fetch(API)
            .then(response => response.json())
            .then(data => this.setState({hits: data.records}));
    }

    handleButton = (modelID) => {
        console.log("trying to go to edit page for " + modelID);
        this.props.history.push("/edit/" + modelID);
    }


    render() {

        console.log("hts: ", this.state.hits)
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-1">
&nbsp;
                        </div>
                        <div className="col-10 newmodelContainer">
                                {this.state.hits.map(casen => (
                                        <div className="newModelDiv" key={casen.ID} id={"mod" + casen.ID} >


                                            <div><img src={casen.bilde2} alt={"Fant ikke bilde"} className="newImg" /></div>
                                            <div>{casen.fornavn} {casen.etternavn}</div>
                                            <button type="button" onClick={() => { this.props.history.push("/new/" + casen.ID); } } className="btn badge-primary">LES MER</button>
                                            { " " }
                                            <button type="button" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteModel(casen.ID) } } className="btn badge-danger">Slett</button>

                                        </div>
                                ))
                                }
                        </div>
                        <div className="col-1">

                        </div>
                    </div>

                </div>



                ;
            </div>
        );

    }
}

//


export default withRouter (Newmodels);
