//@flow
import React, {Component} from "react";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'


import {registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

registerPlugin(FilePondPluginImagePreview);

class Edit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sumitted: false,
            hits: [],
            images: [],
            redigert: [],
            type: "",
            filnavn: "",
            fornavn: "",
            etternavn: "",
            fodt: "",
            adresse: "",
            postnr: "",
            poststed: "",
            telefon: "",
            mobil: "",
            email: "",
            hoyde: "",
            sko: "",
            jeans: "",
            klaer: "",
            byste: "",
            midje: "",
            hofte: "",
            bhcup: "",
            haarfarge: "",
            oyenfarge: "",
            kommentar: "",
            fly: "",
            formresponse: "",
            files: [],
        }
    }

    componentDidMount() {
        const API = 'https://admin.trendmodels.no/backend/newmodels.php?ID=';

        let modID = this.props.match.params.id;

        console.log("URL: " + API + modID)

        fetch(API + modID, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({hits: data.records}));
    }


    render() {

        document.addEventListener('FilePond:loaded', e => {
            console.log('FilePond ready for use', e.detail);
        });

        console.log("hits: ", this.state.hits)
        if (!this.state.hits.length > 0) {
            return <div/>
        } else {
            const {hits} = this.state;

            return (
                <div>
                    <div>
                        {hits.map((casen, index) => (
                            <Container>
                                <Row classname="justify-content-md-center">
                                    <Col sm>
                                        <h2>{casen.fornavn} {casen.etternavn}</h2>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col sm>
                                        <table>
                                            <tr>
                                                <td>
                                                    <table>
                                                        <tr>
                                                            <td><a href={casen.bilde1} rel="noopener noreferrer" target="_blank">
                                                                <img src={casen.bilde1} alt="Bli modell bilde"
                                                                     width="300"/></a>
                                                            </td>

                                                            <td><a href={casen.bilde2} rel="noopener noreferrer" target="_blank">
                                                                <img src={casen.bilde2} alt="Bli modell bilde"
                                                                     width="300"/></a>
                                                            </td>                                                        </tr>
                                                        <tr>

                                                            <td><a href={casen.bilde3} rel="noopener noreferrer" target="_blank">
                                                                <img src={casen.bilde3} alt="Bli modell bilde"
                                                                     width="300"/></a>
                                                            </td>

                                                            <td><a href={casen.bilde4} rel="noopener noreferrer" target="_blank">
                                                                <img src={casen.bilde4} alt="Bli modell bilde"
                                                                     width="300"/></a>
                                                            </td>
                                                        </tr>
                                                    </table>

                                                </td>
                                            </tr>
                                        </table>
                                    </Col>
                                    <Col sm>
                                        <table>

                                            <tr>
                                                <td align="left">Født:</td>
                                                <td align="left">{casen.fodt}</td>
                                            </tr>
                                            <tr>
                                                <td align="left">Adresse:</td>
                                                <td align="left">{casen.adresse}<br/>{casen.postnr} {casen.poststed}<br/></td>
                                            </tr>

                                            <tr>
                                                <td align="left">Mobil:</td>
                                                <td align="left">{casen.mobil}</td>
                                            </tr>
                                            <tr>
                                                <td align="left">Email:</td>
                                                <td align="left"><a href={"mailto:" + casen.email}>{casen.email}</a></td>
                                            </tr>
                                            <tr>
                                                <td align="left">Høyde:</td>
                                                <td align="left">{casen.hoyde} cm</td>
                                            </tr>


                                        </table>
                                    </Col>
                                    <Col xs lg="2">

                                    </Col>
                                </Row>
                            </Container>
                        ))}
                    </div>

                </div>
            )
        }
    }


    save() {

        console.log("redigert: ", this.state);
    }


}

export default Edit;