//@flow
import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import { FilePond, File, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
import { withRouter } from 'react-router-dom'
import axios from "axios";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

class ImageUploadPreviewComponent extends Component {

    fileObj = [];
    fileArray = [];

    constructor(props) {
        super(props)
        this.state = {
            file: [null]
        }
        this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
        this.uploadFiles = this.uploadFiles.bind(this)
    }

    uploadMultipleFiles(e) {
        this.fileObj.push(e.target.files)
        for (let i = 0; i < this.fileObj[0].length; i++) {
            this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
        }
        this.setState({ file: this.fileArray })
    }

    uploadFiles(e) {
        e.preventDefault()
        console.log(this.state.file)
    }

    render() {
        return (
            <form>
                <div className="form-group multi-preview">
                    {(this.fileArray || []).map(url => (
                        <img src={url} style={{height: 100}} alt="..." />
                    ))}
                </div>

                <div className="form-group">
                    <input type="file" className="form-control" onChange={this.uploadMultipleFiles} multiple />
                </div>
                <button type="button" className="btn btn-danger btn-block" onClick={this.uploadFiles}>Upload</button>
            </form >
        )
    }
}

class Edit extends Component {

    constructor(props) {
        super(props);
        const UPLOAD_ENDPOINT = 'https://admin.trendmodels.no/public/newupload.php?id=9999' + this.props.match.params.id;
        this.handleSubmit = this.handleSubmit.bind(this);
        this.save = this.save.bind(this);
        this.postModel = this.postModel.bind(this);
        // this.onChange = this.onChange.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            files: [
                {

                }
            ],
            nextModId: 2,
            cropped: null,
            sumitted: false,
            hits: [],
            images: [],
            redigert: [],
            type: "",
            filnavn: "",
            fornavn: "",
            etternavn: "",
            fodt: "",
            adresse: "",
            postnr: "",
            poststed: "",
            telefon: "",
            mobil: "",
            email: "",
            hoyde: "",
            sko: "",
            jeans: "",
            klaer: "",
            byste: "",
            midje: "",
            hofte: "",
            bhcup: "",
            haarfarge: "",
            oyenfarge: "",
            kommentar: "",
            fly: "",
            instagram: "",
            formresponse: "",
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadPictures();

    }

    handleDrop = (files) => {
        let fileList = this.state.files
        for (var i = 0; i < files.length; i++) {
            if (!files[i].name) return
            fileList.push(files[i].name)
        }
        this.setState({files: fileList})
    }

    loadData() {

        let API = 'https://admin.trendmodels.no/backend/en.php?ID=';
        let DEFAULT_QUERY = this.props.match.params.id;

        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(
                data => this.setState({
                    hits: data.records,
                    ID: data.records[0].ID,
                    type: data.records[0].type,
                    filnavn: data.records[0].filnavn,
                    fornavn: data.records[0].fornavn,
                    etternavn: data.records[0].etternavn,
                    fodt: data.records[0].fodt,
                    adresse: data.records[0].adresse,
                    postnr: data.records[0].postnr,
                    poststed: data.records[0].poststed,
                    telefon: data.records[0].telefon,
                    mobil: data.records[0].mobil,
                    email: data.records[0].email,
                    hoyde: data.records[0].hoyde,
                    sko: data.records[0].sko,
                    jeans: data.records[0].jeans,
                    klaer: data.records[0].klaer,
                    byste: data.records[0].byste,
                    midje: data.records[0].midje,
                    hofte: data.records[0].hofte,
                    bhcup: data.records[0].bhcup,
                    haarfarge: data.records[0].haarfarge,
                    oyenfarge: data.records[0].oyenfarge,
                    kommentar: data.records[0].kommentar,
                    fly: data.records[0].fly,
                    instagram: data.records[0].instagram

                })
            );
    }


    loadPictures() {
        let API = 'https://admin.trendmodels.no/backend/bilder.php?ID=';
        let DEFAULT_QUERY = this.props.match.params.id;

        fetch(API + DEFAULT_QUERY, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({images: data}));
    }

    selectImage(img) {
        const {images} = this.state;
        images.map(imageSrc => {
            document.getElementById(imageSrc.filnavn).classList.remove('testimage2');
        })

        let img2 = img.substring(img.lastIndexOf('/') + 1);
        console.log("img2; " + img2)
        document.getElementById("filnavn").value = img2;
        document.getElementById(img).classList.toggle('testimage2');
        this.setState((state, props) => ({
            filnavn: img2
        }));
    }


    deleteImage(id, filnavn, modellid) {
        let API = 'https://admin.trendmodels.no/backend/deleteimage.php';
        let DEFAULT_QUERY = '?ID=' + id + '&filnavn=' + filnavn + '&modellid=' + modellid;
        const removeFav = this.state.images.filter(item => item.ID !== id)
        this.setState({images: removeFav})
        console.log(API + DEFAULT_QUERY)
        return fetch(API + DEFAULT_QUERY, {
            credentials: 'include',
            method: 'delete',

        })
            .then(console.log("Respons: ", response => response.json()))
    }


    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        console.log("Adding: ", name, " : ", value)
        this.setState((state, props) => ({
            [name]: value
        }));

        // console.log(this.state);
    };

    sendImageToCropping (fil) {
        fil = ("https://admin.trendmodels.no/modeller/" + this.props.match.params.id + "/" + fil)
        this.props.handler(fil, this.props.match.params.id)
        this.props.history.push('/crop')
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;
        if (images != null) {

            return images.map(img => {
                photoIndex++;
                console.log(img.thumbs)
                return (
                    <div key={photoIndex} className="testimage1">
                        <img
                            src={"https://www.trendmodels.no/" + img.thumbs}
                            alt="Modellimg"
                            id={img.filnavn}
                            className="modelimg"
                            onClick={() =>
                                this.selectImage(img.filnavn)
                            }
                        />
                        <button type="button"
                                onClick={() => {
                                    this.sendImageToCropping(img.bilde)
                                }}
                                className="btn badge-primary">Crop
                        </button>
                        { " "}
                        <button type="button"
                                onClick={() => {
                                    if (window.confirm('Are you sure you wish to delete this item?')) this.deleteImage(img.ID, img.bilde, this.props.match.params.id)
                                }}
                                className="btn badge-danger">Slett
                        </button>
                    </div>
                );
            })
        }
    }

    postModel() {
        console.log("type: ", this.state.type)
        console.log("filnavn: ", this.state.filnavn)
        console.log("fornavn: ", this.state.fornavn)


        let url = 'https://admin.trendmodels.no/backend/editmodel.php';
        let data = {
            ID: this.state.ID,
            type: this.state.type,
            filnavn: this.state.filnavn,
            fornavn: this.state.fornavn,
            etternavn: this.state.etternavn,
            fodt: this.state.fodt,
            adresse: this.state.adresse,
            postnr: this.state.postnr,
            poststed: this.state.poststed,
            telefon: this.state.telefon,
            mobil: this.state.mobil,
            email: this.state.email,
            hoyde: this.state.hoyde,
            sko: this.state.sko,
            jeans: this.state.jeans,
            klaer: this.state.klaer,
            byste: this.state.byste,
            midje: this.state.midje,
            hofte: this.state.hofte,
            bhcup: this.state.bhcup,
            haarfarge: this.state.haarfarge,
            oyenfarge: this.state.oyenfarge,
            kommentar: this.state.kommentar,
            fly: this.state.fly,
            instagram: this.state.instagram
        };
        console.log("Sending update\n", JSON.stringify(data))
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Success: ", response.hurra);
                this.setState({
                    formresponse: response.hurra
                })
            })
            .catch(error => console.error('Error:', error));
    }

    handleInit() {
        console.log('FilePond instance has initialised', this.pond);
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            sumitted: true
        })

    }

    render() {

        document.addEventListener('FilePond:loaded', e => {
            console.log('FilePond ready for use', e.detail);
        });

        if (!this.state.hits.length > 0) {
            return <div/>
        } else {
            const {hits} = this.state;

            return (
                <div>
                    <div>
                        {hits.map((modell, index) => (
                            <div className="card card-3" key={index}>
                                <Container>
                                    <Form>
                                        <Row>
                                            <Col>
                                                <h1>Rediger data</h1>

                                                {/* this.state.nextModId
                                                    ? <a href={"/#/edit/" + this.state.nextModId} target="_blank" rel="noopener noreferrer">(Neste)</a>
                                                    : null
                                                */}

                                        {/*        <Form.Group as={Row} controlId="fornavn" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Fornavn
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.fornavn}/>
                                                    </Col>
                                                </Form.Group>*/}


                                                <Form.Group as={Row} controlId="type" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">Type</Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control as="select" defaultValue={modell.type}
                                                                      onChange={this.handleChange}>>
                                                            <option value="newfaces">Women - New faces</option>
                                                            <option value="w_fashion">Women - Fashion</option>
                                                            <option value="w_model">Women - Classic</option>
                                                            <option value="c_girl">Women - Teen / kids</option>
                                                            <option value="m_newfaces">Men - New faces</option>
                                                            <option value="m_fashion">Men - Fashion</option>
                                                            <option value="m_model">Men - Classic</option>
                                                            <option value="c_boy">Men - Teen / kids</option>
                                                            <option value="limbo">Limbo</option>
                                                            <option value="slett">Slett</option>
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} controlId="etternavn" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Etternavn
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.etternavn}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="filnavn" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Filnavn
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.filnavn}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="fodt" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Født
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.fodt}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="instagram" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Instagram
                                                        { " "}
                                                        { modell.instagram
                                                            ? <a href={modell.instagram} target="_blank" rel="noopener noreferrer">(Gå til)</a>
                                                            : null
                                                        }




                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.instagram}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="adresse" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Adresse
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.adresse}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="postnr" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Post nr.
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.postnr}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="poststed" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Poststed
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.poststed}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="telefon" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Telefon
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.telefon}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="mobil" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Mobil
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.mobil}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="email" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        E-mail
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.email}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="hoyde" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Høyde
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.hoyde}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="sko" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Sko
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.sko}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="jeans" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Jeans
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.jeans}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="klaer" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Klær
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.klaer}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="byste" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Byste
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.byste}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="midje" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Midje
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.midje}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="hofte" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Hofte
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.hofte}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="bhcup" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        BH-Cup
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.bhcup}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="haarfarge" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Hårfarge
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.haarfarge}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="oyenfarge" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Øyenfarge
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.oyenfarge}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="fly" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Fly
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.fly}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="kommentar" onChange={this.handleChange}>
                                                    <Form.Label column sm="4">
                                                        Kommentar
                                                    </Form.Label>
                                                    <Col sm="8">
                                                        <Form.Control defaultValue={modell.kommentar}/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row}>
                                                    <Col sm="12">
                                                        <button type="button" onClick={this.postModel}
                                                                className="btn btn-primary">Lagre endringer
                                                        </button>
                                                        <p>{this.state.formresponse}</p>
                                                    </Col>
                                                </Form.Group>


                                            </Col>
                                            <Col>

                                                <h4>Last opp mange bilder</h4><p>Vent til de er lastet opp og så trykk
                                                bekreft opplasting</p>
                             {/*                   <iframe
                                                    src={"https://admin.trendmodels.no/public/index.php?id=" + modell.ID}
                                                    width="400" height="400" frameBorder="0" title="Bildeopplasting"></iframe>
*/}

                                                <div className="App">
                                                    <FilePond
                                                        ref={ref => (this.pond = ref)}
                                                        files={this.state.files}
                                                        allowMultiple={true}
                                                        allowReorder={true}
                                                        maxFiles={100}
                                                        server={"https://admin.trendmodels.no/public/imgupload/index.php?id=" + this.props.match.params.id}
                                                        name="files"
                                                        oninit={() => this.handleInit()}
                                                        onupdatefiles={fileItems => {
                                                            // Set currently active file objects to this.state
                                                            this.setState({
                                                                files: fileItems.map(fileItem => fileItem.file)
                                                            });
                                                        }}
                                                    />
                                                </div>


                                                <hr/>
                                                <h4>Oversikt over bilder: </h4><p>Slett eller crop bilder ved å trykke
                                                på knappene.</p>
                                                {/*
                                                <div className="App">
                                                    <FilePond
                                                        ref={ref => this.pond = ref}
                                                        allowMultiple={true}
                                                        name="filepond[]"
                                                        server="http://admin.trendmodels.no/public/api/submit.php?id=1392"
                                                        oninit={() => this.handleInit() }
                                                        onupdatefiles={(fileItems) => {
                                                            // Set current file objects to this.state

                                                            console.log("Fileitems: " + fileItems.serverId)
                                                            this.setState({
                                                                files: fileItems.map(fileItem => fileItem.file)
                                                            });
                                                        }}>

                                                    </FilePond>
                                                </div>*/}
                                                <div className="mt-5">
                                                    <StackGrid
                                                        gridRef={grid => this.grid = grid}
                                                        itemComponent="div"
                                                        monitorImagesLoaded={true}
                                                        columnWidth={130}
                                                        gutterWidth={20}
                                                    >
                                                        {this.renderImages()}
                                                    </StackGrid>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>;
                                </Container>;
                            </div>
                        ))}
                    </div>

                </div>
            )
        }
    }


    save() {

        console.log("redigert: ", this.state);
    }


}

export default withRouter(Edit);
