import React, {Component} from "react";

class Forside extends Component {

    render() {

        return (
            <div>
                Forside
            </div>
        )
    }
}


export default Forside;
