//@flow
import React, {Component} from "react";
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'


class Edit extends Component {
    constructor(props) {
        super(props);
        this.save = this.save.bind(this);
        this.postModel = this.postModel.bind(this);
        this.state = {
            type: "",
            filnavn: "",
            fornavn: "",
            etternavn: "",
            fodt: "",
            adresse: "",
            postnr: "",
            poststed: "",
            telefon: "",
            mobil: "",
            email: "",
            hoyde: "",
            sko: "",
            jeans: "",
            klaer: "",
            byste: "",
            midje: "",
            hofte: "",
            bhcup: "",
            haarfarge: "",
            oyenfarge: "",
            kommentar: "",
            instagram: "",
            fly: ""
        }
    }


    handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        console.log("Adding: ", name, " : ", value)
        this.setState((state, props) => ({
            [name]: value
        }));

        // console.log(this.state);
    };


    postModel() {
        console.log("type: ", this.state.type)
        console.log("filnavn: ", this.state.filnavn)
        console.log("fornavn: ", this.state.fornavn)


        let url = 'https://admin.trendmodels.no/backend/newmodel.php';
        let data = {
            type: this.state.type,
            filnavn: this.state.filnavn,
            fornavn: this.state.fornavn,
            etternavn: this.state.etternavn,
            fodt: this.state.fodt,
            adresse: this.state.adresse,
            postnr: this.state.postnr,
            poststed: this.state.poststed,
            telefon: this.state.telefon,
            mobil: this.state.mobil,
            email: this.state.email,
            hoyde: this.state.hoyde,
            sko: this.state.sko,
            jeans: this.state.jeans,
            klaer: this.state.klaer,
            byste: this.state.byste,
            midje: this.state.midje,
            hofte: this.state.hofte,
            bhcup: this.state.bhcup,
            haarfarge: this.state.haarfarge,
            oyenfarge: this.state.oyenfarge,
            kommentar: this.state.kommentar,
            instagram: this.state.instagram,
            fly: this.state.fly
        };

        fetch(url, {
            credentials: 'include',
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            headers:{
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Success: ", response.hurra);
                console.log("Response: ", response);
                let nyURL = ('#/edit/' + response.nyId);
                this.props.history.push(nyURL);
                window.location.reload();
    })
            .catch(error => console.error('Error:', error));
    }

    save() {

        console.log("type: ", this.state.type)
        console.log("filnavn: ", this.state.filnavn)
        console.log("fornavn: ", this.state.fornavn)
        fetch('https://admin.trendmodels.no/backend/newmodel.php',{
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type':'application/json'},
            body: {
                type: this.state.type,
                filnavn: this.state.filnavn,
                fornavn: this.state.fornavn,
                etternavn: this.state.etternavn,
                fodt: this.state.fodt,
                adresse: this.state.adresse,
                postnr: this.state.postnr,
                poststed: this.state.poststed,
                telefon: this.state.telefon,
                mobil: this.state.mobil,
                email: this.state.email,
                hoyde: this.state.hoyde,
                sko: this.state.sko,
                jeans: this.state.jeans,
                klaer: this.state.klaer,
                byste: this.state.byste,
                midje: this.state.midje,
                hofte: this.state.hofte,
                bhcup: this.state.bhcup,
                haarfarge: this.state.haarfarge,
                oyenfarge: this.state.oyenfarge,
                kommentar: this.state.kommentar,
                instagram: this.state.instagram,
                fly: this.state.fly
            }
        });

        console.log("redigert: ", JSON.stringify(this.state));
    }

    render() {




        return (
            <div>
                <div>
                    <div className="card card-3">
                        <Container>
                            <Form>
                                <Row>
                                    <Col>
                                        <h1>Legg til modell</h1>

                                        <Form.Group as={Row} controlId="type" onChange={this.handleChange}>
                                            <Form.Label column sm="4">Type</Form.Label>
                                            <Col sm="8">
                                                <Form.Control as="select" onChange={this.handleTemplateChange}>>
                                                    <option>Velg type</option>
                                                    <option value="newfaces">Women - New faces</option>
                                                    <option value="w_fashion">Women - Fashion</option>
                                                    <option value="w_model">Women - Classic</option>
                                                    <option value="c_girl">Women - Teen / kids</option>
                                                    <option value="m_newfaces">Men - New faces</option>
                                                    <option value="m_fashion">Men - Fashion</option>
                                                    <option value="m_classic">Men - Classic</option>
                                                    <option value="c_boy">Men - Teen / kids</option>
                                                    <option value="limbo">Limbo</option>
                                                    <option value="slett">Slett</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="fornavn" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Fornavn
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="etternavn" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Etternavn
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="filnavn" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Filnavn
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="fodt" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Født
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="adresse" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Adresse
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="postnr" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Post nr.
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="poststed" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Poststed
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="telefon" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Telefon
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="mobil" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Mobil
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="email" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                E-mail
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="hoyde" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Høyde
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="sko" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Sko
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="jeans" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Jeans
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="klaer" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Klær
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="byste" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Byste
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="midje" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Midje
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="hofte" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Hofte
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="bhcup" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                BH-Cup
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="haarfarge" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Hårfarge
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="oyenfarge" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Øyenfarge
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="fly" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Fly
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="instagram" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Instagram
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="kommentar" onChange={this.handleChange}>
                                            <Form.Label column sm="4">
                                                Kommentar
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control/>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row}>
                                            <Col sm="12">
                                                <button type="button" onClick={this.postModel}
                                                        className="btn btn-primary">Lagre endringer
                                                </button>
                                            </Col>
                                        </Form.Group>


                                    </Col>
                                    <Col>
                                        <div className="mt-5">

                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Container>
                    </div>

                </div>

            </div>
        )
    }





}

export default Edit;