//@flow
import React, {Component} from "react";
import Models from "./Models";
import Button from 'react-bootstrap/Button';
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

class Oversikt extends Component<{
    match: { params: { gruppe: string } }
}> {
    constructor(props, context) {
        super(props, context);

        this.state = {
            gruppe: this.props.match.params.gruppe,
            checked: []
        };

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleEditButton = this.handleEditButton.bind(this);
    }


    handleCheckbox(model) {
        let mod = this.state.checked;
        if (mod.includes(model)) {
            console.log("already exists in array")
            let index = mod.indexOf(model);
            if (index !== -1) mod.splice(index, 1);
        } else {
            this.setState({
                checked: this.state.checked.concat(model)
            });
        }
        console.log("state: ", this.state.checked)
    }

    handleEditButton(model) {
        this.props.history.push("#/edit/" + model)
        console.log("model: ", model)
    }

    refreshModels = () => {
        this.setState({refreshModels: !this.state.refreshModels})
        console.log("Refreshing models")
    }

    showGroup(g) {
        this.setState({
            gruppe: g
        })

    }


    render() {
        console.log("state:", this.state.gruppe)

        let showmodels = (
            <Models history={history}
                    handleCheckbox={this.handleCheckbox} handleEditButton={this.handleEditButton}
                    filter={this.state.gruppe}/>
        )
        return (
            <div>

                <h3>Modell-liste</h3>
                <div className="Modellkontainer">
                    <div className="buffer"></div>
                    <div className="Sidebar">
                        <h2>Women</h2>
                        <Button className="submenubutton" onClick={() => this.showGroup("newfaces")} variant="info">New faces</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("w_fashion")} variant="info">Fashion</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("w_model")} variant="info">Classic</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("c_girl")} variant="info">Kids/Teens</Button>
                        <hr/>

                        <h2>Men</h2>
                        <Button className="submenubutton" onClick={() => this.showGroup("m_newfaces")} variant="info">New faces</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("m_fashion")} variant="info">Fashion</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("m_model")} variant="info">Classic</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("c_boy")} variant="info">Kids/Teens</Button>
                        <hr/>
                        <h2>Div</h2>
                        <Button className="submenubutton" onClick={() => this.showGroup("limbo")} variant="info">Limbo</Button>
                        <Button className="submenubutton" onClick={() => this.showGroup("slett")} variant="info">Slett</Button>
                        <hr/>

                    </div>
                    <div className="Mainbar">
                    <form onSubmit={this.handleSubmit}>

                        <div className="row mb-3">
                            <div className="col-6 col-sm-4 themed-grid-col">
                                {showmodels}
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="buffer2"></div>
                </div>
            </div>
        );


    }
}

export default Oversikt;