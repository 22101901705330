import React, {Component} from "react";
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;



const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    console.log("result: ", result)
    return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 1000
});



class ForsidebilderOversikt extends Component {
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
        this.state = {
            hits: [],
            items: [],
            formresponse: ""

        }
    }

    componentDidMount() {
        this.loadData();
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        let recipesCopy = JSON.parse(JSON.stringify(this.state.hits))

        const items =  reorder(recipesCopy, result.source.index, result.destination.index)

            this.setState({
                hits:items
            })


    }

    loadData() {

        let API = 'https://admin.trendmodels.no/backend/forside.php';

        console.log("Fetsher ", API)
        fetch(API, {credentials: 'include'})
            .then(response => response.json())
            .then(data => this.setState({hits: data.records}));
    }


    deleteForsidebilde(id) {
        console.log("Sletter forside med id " + id)
        // console.log(this.state.hits)

        const removeFav = this.state.hits.filter(item => item.ID !== id)
        this.setState({
           hits: removeFav
        })
        let API = 'https://admin.trendmodels.no/backend/deleteforside.php?ID=';
        let DEFAULT_QUERY = id;
        return fetch(API + DEFAULT_QUERY, {
            credentials: 'include',
            method: 'delete'
        })
            .then(response => response.json());
    }

    handleEdit(e) {
        this.props.handleEditButton(e);
    }


    handleOrder(e) {
        console.log("lagrer rekkefølge.")
        console.log("this.state.hits: ", this.state.hits)
        this.postForside()
    }


    postForside() {

        let url = 'https://admin.trendmodels.no/backend/forside_order.php';
        let data = {
            data: this.state.hits,

        };
        console.log("Sending update\n", JSON.stringify(data))
        fetch(url, {
            method: 'POST', // or 'PUT'
            body: JSON.stringify(data), // data can be `string` or {object}!
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Success: ", response.hurra);
                this.setState({
                    formresponse: response.hurra
                })
            })
            .catch(error => console.error('Error:', error));
    }


    render() {

        return (
            <div>

                <h1>Forsidebilder

                </h1>
                <Link to={"/nyforside/"}>{" "}
                    <Button type="button">Legg til ny</Button>
                </Link>
                <p>{this.state.formresponse}</p>
                <button type="button" onClick={() => {
                    if (window.confirm('Are you sure you want to save this order?')) this.handleOrder()
                }} className="btn badge-danger">Save changes
                </button>

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {this.state.hits.map((item, index) => (
                                    <Draggable key={item.ID} draggableId={item.ID} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div key={item.ID} className="forsidecontainer">
                                                    <div className="forsidecelle"><img height="100"
                                                             src={"http://www.trendmodels.no/modeller/" + item.modellID + "/thumbs/" + item.filnavn}/>
                                                    </div>
                                                    <div className="forsidecelle">{item.modellNavn}</div>
                                                    <div className="forsidecelle">{item.publikasjon}</div>
                                                    <div className="forsidecelle">{item.fotograf}</div>
                                                    <div className="forsidecelle">
                                                        <Link to={"/forside/" + item.ID}>
                                                            <Button type="button">Edit</Button>
                                                        </Link>
                                                    </div>
                                                    <div className="forsidecelle">

                                                        <button type="button" onClick={() => {
                                                            if (window.confirm('Are you sure you wish to delete this item?')) this.deleteForsidebilde(item.ID)
                                                        }} className="btn badge-danger">Slett
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        )
    }
}


export default ForsidebilderOversikt;
