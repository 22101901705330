import React from 'react'
import {withRouter} from 'react-router-dom'
import Slider from '@material-ui/lab/Slider'
import Button from '@material-ui/core/Button'

import Cropper from 'react-easy-crop'

import getCroppedImg from './cropImage'
import './styles.css'

class Crop extends React.Component {
    state = {
       imageSrc: this.props.filetocrop,
        modellID: this.props.cropmod,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 10 / 12,
        croppedAreaPixels: null,
        croppedImage: null,
        senddata: [],
        buttoncolor: "primary",
        buttontext: "Crop"
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        // console.log(croppedArea, croppedAreaPixels)
        console.log("width:", croppedAreaPixels.width , " - height: ", croppedAreaPixels.height, " - modellID: ", this.state.modellID)
        if ((croppedAreaPixels.width >= 300) || (croppedAreaPixels.height >= 360)) {
            this.setState(() => ({
                buttoncolor: "primary",
                buttontext: "Crop"
            }));
        } else {
            this.setState(() => ({
                buttoncolor: "secondary",
                buttontext: "For lite!"
            }));
        }

        this.setState({ croppedAreaPixels })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        )
        // console.log(croppedImage)

        this.setState({ croppedImage })

        let senddata = {
            imgdata: croppedImage,
            filnavn: this.props.filetocrop.substring(this.props.filetocrop.lastIndexOf('/')+1),
            modID: this.state.modellID,
        }

        console.log("senddata: ", senddata)

        fetch("https://admin.trendmodels.no/div/savebase64img.php", {
            credentials: 'include',
            method: 'POST', // or 'PUT'
            body: JSON.stringify(senddata), // data can be `string` or {object}!
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then(response => {
                console.log("Response: ", response);
                this.props.history.push('/edit/' + this.state.modellID)
            })




    }

    handleClose = () => {
        this.setState({ croppedImage: null })
    }

    render() {

        return (
            <div className="App">
                <div className="crop-container">
                    <Cropper
                        image={this.state.imageSrc}
                        crop={this.state.crop}
                        zoom={this.state.zoom}
                        aspect={this.state.aspect}
                        onCropChange={this.onCropChange}
                        onCropComplete={this.onCropComplete}
                        onZoomChange={this.onZoomChange}
                    />
                </div>
                <div className="controls">
                    <Slider
                        value={this.state.zoom}
                        min={1}
                        max={2}
                        step={0.1}
                        aria-labelledby="Zoom"
                        onChange={(e, zoom) => this.onZoomChange(zoom)}
                        classes={{ container: 'slider' }}
                    />{" "}
                    <Button
                        onClick={this.showCroppedImage}
                        variant="contained"
                        color={this.state.buttoncolor}
                    >{this.state.buttontext}</Button>
                </div>

            </div>
        )
    }
}


export default withRouter(Crop);
