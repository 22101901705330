//@flow
import React, {Component} from "react";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'


class Models extends Component<{
    match: { params: { id: number } }
}> {
    constructor(props) {
        super(props);
        this.state = {
            hits: [],
            checked: []
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
    }

    state = {}

    handleChange(e) {
        this.props.handleCheckbox(e.target.value);
    }

    deleteModel(id) {
        console.log("Sletter modell med id " + id)
        console.log(this.state.hits)

        const removeFav = this.state.hits.filter(item => item.ID !== id)
        this.setState({
            hits: removeFav
        })
        let API = 'https://admin.trendmodels.no/backend/deletemodel.php?ID=';
        let DEFAULT_QUERY = id;
        return fetch(API + DEFAULT_QUERY, {
            credentials: 'include',
            method: 'delete'
        })
            .then(response => response.json());
    }

    handleEdit(e) {
        this.props.handleEditButton(e);
    }


    componentDidMount() {
        const API = 'https://admin.trendmodels.no/backend/all.php?filter=';
        const DEFAULT_QUERY = this.props.filter;

        console.log("Fetsher ", API + DEFAULT_QUERY)
        fetch(API + DEFAULT_QUERY, { credentials: 'include' })
            .then(response => response.json())
            .then(data => this.setState({hits: data.records}));
    }

    componentWillReceiveProps(nextProps) {

        console.log("this.props.filter ", this.props.filter)
        console.log("nextProps.filter ", nextProps.filter)

        if (this.props.filter !== nextProps.filter) {

            console.log("Loading new data: ", nextProps.filter)
            const API = 'https://admin.trendmodels.no/backend/all.php?filter=';
            const DEFAULT_QUERY = nextProps.filter;

            fetch(API + DEFAULT_QUERY, { credentials: 'include' })
                .then(response => response.json())
                .then(data => this.setState({hits: data.records}));
        }
    }

    handleButton = (modelID) => {
        console.log("trying to go to edit page for " + modelID);
        this.props.history.push("/edit/" + modelID);
    }


    render() {

        console.log("hits from " + this.props.filter + ": ", this.state.hits)
        return (
            <div>
                <table border="0" width="800">
                    <thead>
                    <tr>
                        <th> </th>
                        <th>Navn</th>
                        <th>Alder</th>
                        <th>Mobil</th>

                        <th>Ig</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.hits.map(casen => (
                        <tr key={casen.ID}>
                            {/* <td>
                                <div className="container">
                                    <div className="round">
                                        <input type="checkbox" name="modeller[]" onChange={this.handleChange} id={"checkbox-" + casen.ID} value={casen.ID}/>
                                        <label htmlFor={"checkbox-" + casen.ID}></label>
                                    </div>
                                </div>
                            </td> */}
                            <td key={casen.ID} className="modellcelle2">
                                <img
                                    src={"https://www.trendmodels.no/modeller/" + casen.ID + "/thumbs/" + casen.filnavn}
                                    alt={"img"}
                                    style={{height: 100}}
                                />
                            </td>
                            <td className="modellcelle">{casen.fornavn} {casen.etternavn}</td>
                            <td className="modellcelle">{(new Date().getFullYear()) - casen.fodt}</td>
                            <td className="modellcelle">{casen.mobil}</td>

                            <td className="modellcelle">
                                {casen.instagram ? <a target="_blank" href={casen.instagram} rel="noopener noreferrer" ><div className="social-icon social-icon--instagram"></div></a> : null }</td>
                            <td>
                                <Link target="_blank" to={"/edit/" + casen.ID}>
                                <Button>Edit</Button>
                        </Link>
                            </td>
                            {this.props.filter === "slett" ?
                                <td>
                                    <button type="button" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) this.deleteModel(casen.ID) } } className="btn badge-danger">Slett</button>
                                </td>
                                : <td></td>}


                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                ;
            </div>
        );

    }
}

//


export default Models;
