//@flow
import React, {Component} from "react";
import { Route } from "react-router-dom";
import Add from "./Add";
import Crop from "./Crop";
import Oversikt from "./Oversikt";
import Newmodel from "./Newmodel";
import Newmodels from "./Newmodels";
import Forside from "./Forside";
import Frontpage from "./Frontpage";
import ForsidebilderOversikt from "./ForsidebilderOversikt";
import FrontpageNew from "./FrontpageNew";

import Edit from "./Edit";
import { createBrowserHistory } from "history";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { HashRouter } from "react-router-dom";

const history = createBrowserHistory();

class ModelList extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            cropimg: "",
            cropmod: "",
            checked: []
        };
        this.handler = this.handler.bind(this)

    }

    handler(someValue, modid) {
        // console.log("handler: ", modid)
        this.setState({
            cropimg: someValue,
            cropmod: modid

        })
    }


    render() {
        return (
            <div className="App">

                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand href="/">TREND MODELS ADMIN-SIDE</Navbar.Brand>
                    <Nav className="mr-auto">
                        <Nav.Link href="/#/modeller/">Modell-liste</Nav.Link>
                        <Nav.Link href="/#/add/">Legg til modell</Nav.Link>
                        <Nav.Link href="/#/new/">Nye modeller</Nav.Link>
                        <Nav.Link href="/#/forside">Forside-oversikt</Nav.Link>

                    </Nav>
                </Navbar>

                <HashRouter history={history}>

                    <Route
                        exact
                        path="/modeller"
                        render={(props) => <Oversikt {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/nyforside"
                        render={(props) => <FrontpageNew {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/"
                        render={(props) => <Forside {...props} history={history} />}
                    />
                        <Route
                            exact
                            path="/edit/:id"
                            render={(props) => <Edit {...props} history={history} handler = {this.handler} />}
                        />
                    <Route
                        exact
                        path="/add"
                        render={(props) => <Add {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/new"
                        render={(props) => <Newmodels {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/forside/:id"
                        render={(props) => <Frontpage {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/forside"
                        render={(props) => <ForsidebilderOversikt {...props} history={history} />}
                    />

                    <Route
                        exact
                        path="/new/:id"
                        render={(props) => <Newmodel {...props} history={history} />}
                    />
                    <Route
                        exact
                        path="/crop"
                        render={(props) => <Crop {...props} filetocrop={this.state.cropimg} cropmod={this.state.cropmod} history={history} />}
                    />

                </HashRouter>

            </div>

        );
    }
}

export default ModelList;
